import React from "react";
import Props from "prop-types";
import Close from "./close.png";
import {StateButton} from "../StateButton";
import White_close from './cross_white.png';
import {connect} from 'react-redux';

class Modal1 extends React.Component {


    render() {
        let { theme } = this.props;
        if (this.props.external) {
            theme = "light"
        }


        return (
            <div className="modal fade show" style={this.props.show ? { display: "block", background: 'rgba(0,0,0,.4)' } : {}}>
                <div className="modal-dialog" role="document" style={{ marginTop: 80, ...this.props.modalDialog }}>
                    <div className="modal-content">
                        <div >
                            <h5 className="p-portlet-title" id="exampleModalLabel">
                                {this.props.title}
                            </h5>
                            {
                                this.props.desc ? <p className="p-portlet-des">
                                    {
                                        this.props.desc
                                    }
                                </p> : <div style={{ marginTop: 15 }} />
                            }
                            <img alt="close" style={{ width: 20, height: 20, marginTop: 10, cursor: 'pointer' }} src={theme === 'dark' ? White_close : Close} className="modal-close" onClick={this.props.close} />
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        <div className="modal-footer">
                            {!this.props.hideButton ? <div style={this.props.style.save} onClick={this.props.stateLoading ? null : this.props.onSave} className="save">
                                {this.props.stateLoading ? <StateButton /> : this.props.buttonName}
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

Modal1.propTypes = {
    onSave: Props.func.isRequired,
    title: Props.string.isRequired,
    buttonName: Props.string,
    show: Props.any,
    close: Props.func.isRequired,
    style: Props.object,
    hideButton: Props.bool,
}


Modal1.defaultProps = {
    buttonName: "Save Changes",
    hideButton: false,
    style: {
        close: {

        },
        save: {

        }
    }
}

const Modal = connect(state => {
    return {
        theme: state.auth.theme
    }
}, null)(Modal1);

export {
    Modal
};
