import {trackApis} from "../Config/Axios";

const trackData = (data) => {
	data.path = window.location.href;
	data.data = JSON.stringify(data.data);
	trackApis(data)
}

export {
	trackData
}
