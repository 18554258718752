import React, {useEffect, useState} from 'react'
import Google from './Google'
// import Saml from './Saml'
import axios from 'axios'
import {useHistory} from 'react-router-dom'
import Spinner from '../../Component/Common/Loader';

function Index(props) {
	
    const HISTORY = useHistory();

	let email = new URLSearchParams(window.location.search).get('email')
	if(!email){
		HISTORY.push('/')
	}

	const [SsoDetails, setSsoDetails] = useState(undefined);

	useEffect(() => {
		ssoType(email,HISTORY)
	}, [email,HISTORY]);

	const ssoType = (username,HIS)=>{
		axios.post(`https://heimdall.eka.io/employee/auth/username`, {username}).then(res => {
			if(!res.data.sso){
				HIS.push('/')
			}
			setSsoDetails(res.data)
        }).catch(err => {
			console.log(err)
        })
	}

	if(SsoDetails){
		if(SsoDetails.ssoType==="GOOGLE"){
			return (<Google data={{...SsoDetails, username: email}}/>)
		}
		if(SsoDetails.ssoType==="SAML"){
			// return (<Saml url={SsoDetails.ssoUrl} data={SsoDetails}/>)
			return
		}
	}else{
		return (
			<div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				<Spinner/>
			</div>
		)
	}
}

export default Index;
