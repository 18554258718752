import React, {useEffect} from 'react'
import Back from "../Login/emp.jpg";
import Spinner from "react-spinner-material";
import {useLocation,Redirect} from 'react-router-dom'
import axios from 'axios'
import * as Crypto from "../../Utils/Crypto";
import {AuthActions} from "../../Redux/Reducer/Auth";
import {useDispatch, useSelector} from "react-redux";
import Moment from "moment";
import {showToast} from '../../Component/Common/Toast/index.js';

export default function Index() {

	const {search} = useLocation()
	const dispatch = useDispatch()
	let {config} = useSelector((state => ({config: state.auth})));
	
	useEffect(()=>{
		const params = new URLSearchParams(search);

		if(params.get("grant_type")==='sso'){
			axios.post(`https://heimdall.eka.io/employee/auth/sso/google/code`, { code: params.get("code"), redirect: 'postmessage' }).then(res => {
					let r = res.data;
					if(r.access_token&&r.refresh_token&&r.name){
						Crypto.set('token', r.access_token);
						Crypto.set('refresh_token',r.refresh_token);
						Crypto.set("lastlogin", Moment().valueOf())
						Crypto.set("loggedin", "yes");
						Crypto.set("name", r.name)
						dispatch({
							type: AuthActions.Login,
							data: {
								name: params.get("name"),
								login: Moment()
							}
						})
					}else{
						showToast({
							type:'error',
							message:'SSO is not enabled for this account'
						})
					}
			}).catch(err => {
				showToast({
					type: 'error',
					message: err.data?err.data.message:"Somethin went Wrong !"
			})
			})
		}else{
			let data = {
				code : params.get("code"),
				type : params.get("grant_type"),
				baseUrl : params.get("apiOrigin"),
				client_id : '62a1d915cff47e00xas32asf32asfd915cff47e0007bfb69d'
			}
			axios.post(`${data.baseUrl}employee/auth/v2/authorize`, data).then(x => {
				let r = x.data;
				if(r){
					Crypto.set('token', r.access_token);
					Crypto.set('refresh_token', r.refresh_token);
					Crypto.set("lastlogin", Moment().valueOf())
					Crypto.set("loggedin", "yes");
					Crypto.set("name", r.name)
					dispatch({
						type: AuthActions.Login,
						data: {
							name: data.name,
							login: Moment()
						}
					})
				}
			}).catch(err => {
				showToast({
				  type: 'error',
				  message: "Somethin went Wrong !"
			})
		})	
		}
	},[dispatch, search])
	
	if (config.authenticated) {
		return  (
			<Redirect to="/app/home"/>
		)
	}

	return (
		<div style={{
			position: 'relative',
			background: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)) center / cover no-repeat, url('${Back}') center / cover no-repeat`,
			width: '100%',
			height: '100vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}>
			<div className="FadeRight-Effect" style={{
				boxShadow:'rgba(0, 0, 0, 0.25) 0px 0.25rem 0.25rem',
				background:'rgba(235,235,235,0.9',
				backdropFilter: 'blur(10px)',
				zIndex:2,
				borderRadius:12,
				padding:'55px 45px',
			}}>
				<Spinner radius={36} visible={true} color="var(--primary-color)"/>
			</div>
		</div>
	)
}