import React from 'react';
import {post} from '../../Model/Network/Config/Axios';
import Query from 'querystring'
import {PageLoader} from '../../Component/Common/PageLoader';


class Index extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            success: false
        }
    }

    componentDidMount() {

        let data = this.props.location.search.substr(1)
        data = Query.parse(data)
        let token = data.token;
        
        if (!token) {
            this.setState({
                success: false,
                loading: false,
                message: "No token found in string"
            })
        } else {
            
            post('/others/action', { token }, (e, r) => {
                if (e) {
                    this.setState({
                        success: false,
                        loading: false,
                        message: e.data.error
                    })
                } else {
                    this.setState({
                        success: true,
                        loading: false,
                        message: "Action completed successfully!"
                    })
                }
            })
        }
    }

    render() {
        if (this.state.loading) {
            return (<PageLoader />)
        }
        return (
            <div>
                <div style={{
                    width: '100%',
                    height: '100vh',
                    background: "#f2f2f2",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        padding: '25px',
                        width: 550,
                        background: "#ffffff",
                        border: '1px solid #dedede',
                        borderRadius: 4,
                        margin: '0 auto',
                        display: 'inline-block'
                    }}>
                        <p style={{
                            fontSize: 41,
                            color: this.state.success ? "#6367FA" : "#FF5632",
                            fontWeight: 800
                        }}>{!this.state.success ? "Ooops!" : "Success"}</p>
                        <p style={{
                            fontSize: 19,
                            marginTop: 4,
                            color: "#676767",
                            fontWeight: 400
                        }}>{this.state.message}</p>
                    </div>
                </div>
            </div>
        )
    }


}

export default Index;
