import {get} from "../../../Utils/Crypto";

const DEVELOPMENT_MODE = false;

let api, luna, token, track;


api = "https://heimdall.eka.io/employee/";
luna = "luna";
token = () => get("refresh_token");
track = "https://track.eka.io/employee/";


export {
	api, luna, DEVELOPMENT_MODE, token, track
}
