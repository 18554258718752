import React from "react";
import './index.css'

function Spinner(props){
    return(
    <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center',padding: '1rem',...props.style}}>
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>)
}

export default Spinner;
