import React from 'react';
import ReactDOM from 'react-dom';
import './Component/Common/index.css';
import 'rc-slider/assets/index.css';
import App from './App';
import 'react-medium-image-zoom/dist/styles.css'
import './base.css'
import './override.css'

ReactDOM.render(<App />, document.getElementById('root'));

if ("serviceWorker" in navigator) {
	navigator.serviceWorker
		.register("/firebase-messaging-sw.js")
		.then(function (registration) {
			console.log("Registration successful, scope is:", registration.scope);
		})
		.catch(function (err) {
			console.log("Service worker registration failed, error:", err);
		});
}
