import React from 'react';


class NormalTable extends React.Component {

    state = {
        page: 0,
        count: 1
    }




    mapHeaders = (item, index) => {
        return (
            <div key={index} style={
                item.width ? { width: item.width } : item.weight ? { flex: item.weight } : { flex: 1 }
            }>
                <p style={{
                    color: 'var(--title-desc-color)',
                    fontWeight: 600,
                    ...item.style
                }}>
                    {item.name}
                </p>
            </div>
        )
    }


    pagination = () => {
        let { data } = this.props;
        let start = data.length > 0 ? this.state.page * 10 + 1 : 0;
        let end = this.state.count * 10 >= this.props.data.length ? this.props.data.length : this.state.count * 10;

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 25,
                fontWeight: 600,
                color: 'var(--sidebar-active-color)'
            }}>
                <div className="flex flex-v-center" >
                    {start} - {end} of {this.props.data.length}
                    <div className="flex flex-v-center flex-h-center" style={{
                        marginLeft: 10,
                        border: '1px solid var(--border-color)',
                        height: 30,
                        width: 120,
                        borderRadius: 2
                    }}>
                        <p className="flex flex-v-center flex-h-center" style={{
                            cursor: 'pointer',
                            borderRight: '1px solid var(--border-color)',
                            height: 'inherit',
                            paddingRight: 10
                        }} onClick={() => {
                            let count = this.state.count;
                            if (count > 1) {
                                this.setState({
                                    count: count - 1,
                                    page: this.state.page - 1
                                })
                            }
                        }}>Prev</p>
                        <p style={{
                            cursor: "pointer",
                            paddingLeft: 10
                        }} onClick={() => {
                            if (this.props.data.length >= this.state.count * 10) {
                                this.setState({
                                    count: this.state.count + 1,
                                    page: this.state.page + 1
                                })
                            }
                        }}>Next</p>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let { data } = this.props;
        let start = data.length > 0 ? this.state.page * 10 + 1 : 0;
        let end = this.state.count * 10 >= this.props.data.length ? this.props.data.length : this.state.count * 10;
        data = data.slice(start - 1, end);
        return (
            <div style={{
                flex: 1,
                marginTop: 20
            }}>
                <div style={{ flex: 1, display: 'flex' }}>
                    {
                        this.props.headers.length > 0 ?
                            this.props.headers.map(this.mapHeaders) : null
                    }
                </div>
                {data.length === 0 ?
                    <div style={{
                        color: 'var(--sidebar-active-color)',
                        textAlign: 'center',
                        fontSize: 18,
                        fontWeight: 600
                    }}>No Data Found</div>
                    :
                    data.map(this.props.mapItem)}

                {this.pagination()}
            </div>
        )
    }
}

NormalTable.defaultProps = {
    headers: []
}

export { NormalTable };
