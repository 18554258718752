import React from 'react'
import Spinner from "react-spinner-material";

class PageLoader extends React.Component {

	render() {
		return (
			<div style={{
				flex: 1,
				width: '100%',
				padding: 30
			}}>
				<div style={{
					margin: '0 auto',
					background: "#fff",
					borderRadius: 30,
					height: 50,
					width: 50,
					padding: 8,
					boxShadow: "1px 1px 1px rgba(1,1,1,.2)"
				}}>
					<Spinner radius={34}  visible={true} />
				</div>
			</div>
		)
	}

}

export {
	PageLoader
}
