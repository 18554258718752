import React from 'react';
import Spinner from '../../Component/Common/Loader'

function Index(props) {
    return (
        <div style={{width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Spinner/>
        </div>
    );
}

export default Index;