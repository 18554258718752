import React, {useRef} from 'react';
import './index.css'

export default function Index(props) {

    let RedirectURI = window.location.href;
    let AuthFrame = useRef(null);

    const startAuth = () => {
        let href = null;
        try {
            href = AuthFrame.current.contentWindow.location.href
            if(href.split('?')[0].includes(RedirectURI)){
                AuthFrame.current.style.display='none'
                window.location.replace(`${RedirectURI}authorize/?${href.split('?')[1]}`)

            }
        } catch (err) {
            console.log('ERR=> ',href)
        }
    }

    return (
        <>
            <iframe title='Dice_Auth' onLoad={startAuth} style={{width:'100%', height: '640px', overflow: 'hidden'}} ref={AuthFrame} src={`https://oauth.dice.tech?redirect_uri=${RedirectURI}loading`}/>
        </>
    );
}
