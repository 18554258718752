import React from 'react';


class StateButton extends React.Component {


    render() {
        return (
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        );
    }
}


export { StateButton }