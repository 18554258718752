import React from 'react'
import {GoogleLogin} from 'react-google-login';
import bs from '../../Login/bootstrap.module.css'
import Google_Ico from './google.png'

function Index(props) {

    const responseGoogle = (response) => {
        
        if (response.code) {
            window.location.replace(`https://app.dice.tech/loading?grant_type=sso&code=${response.code}`)
        }
    }

    return ( 
        <div data-aos="fade-left" className={`${bs['p-5']} ${bs['text-center']}`} style={{
            margin: 'auto',
            backgroundColor: '#fff',
            borderRadius: '1rem',
            width: '100%',
            minWidth: '280px',
            height:'100vh',
            display:'flex',
            justifyContent:'center',
            alignItems: 'center'
        }}>
            <div>
                <img className={`${bs['img-fluid']} ${bs['mb-4']} ${bs['m-auto']}`} src={Google_Ico} style={{maxWidth: '80px'}} alt=""/>
                <h1 className={`${bs['display-6']} ${bs['fw-bold']}`}>Continue</h1>
                <p style={{marginBottom: '40px'}}>You have SSO enabled for your account</p>

                <GoogleLogin
                    clientId="577109105568-e65ha2mpib84tua3o06b2qrjnldosutk.apps.googleusercontent.com"
                    onSuccess={responseGoogle}
                    accessType="offline"
                    uxMode="popup"
                    approvalPrompt="force"
                    prompt='consent'
                    loginHint={props.data.username}
                    responseType="code"
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}/>

                <div style={{
                    fontSize: '12px',
                    background: '#0d6efd',
                    color: '#fff',
                    padding: '12px 14px',
                    width: '180px',
                    borderRadius:'5px',
                    margin: 'auto',
                    marginTop: '15px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between',
                    cursor: 'pointer'
                }}
                onClick={()=>window.location.replace(`https://oauth.dice.tech?temp_login=${props.data.username}`)}
                >
                    <svg style={{fontSize:'16px'}} xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                    </svg>
                    Use One-Time Pasword
                </div>
            </div>
        </div>
     );
}

export default Index;