import React from 'react';
import Query from 'querystring'
import {get, post} from '../../Model/Network/Config/Axios';
import {PageLoader} from '../../Component/Common/PageLoader';
import {NormalTable} from '../../Component/Common/Table/NormalTable';
import {Modal} from '../../Component/Common/Modal/Modal';
import {FloatInput1} from '../../Component/Common/FloatInput1/index';
import './index.css';
import {showToast} from '../../Component/Common/Toast/index.js';


class Index extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: undefined,
            decline: undefined
        }
    }


    componentDidMount() {
        this.init();
    }

    init = () => {
        let data = this.props.location.search.substr(1)
        data = Query.parse(data)
        let token = data.token;
        if (!token) {
            this.setState({
                success: false,
                loading: false,
                message: "No token found in string"
            })
        } else {
            get('/others/action/voucher', (e, r) => {
                if (e) {
                    // this.setState({
                    //     success: false,
                    //     loading: false,
                    //     message: e.data.message
                    // })
                } else {
                    this.setState({
                        success: true,
                        loading: false,
                        message: "Action completed successfully!",
                        data: r
                    })
                }
            }, {
                token
            })
        }
    }



    mapItem = (item, index) => {
        return (
            <div key={index} className="flex"
                style={{
                    borderBottom: '1px solid var(--border-color)',
                    alignItems: 'center',
                    padding: '10px 0px'
                }}>
                <div style={{ height: 60, display: 'flex', alignItems: 'center', flex: 1, color: 'var(--sidebar-active-color)' }}>
                    <div style={{ flex: 1 }}>
                        <p style={{
                            fontSize: 14,
                            fontWeight: 700
                        }}>#{item.transId}</p>

                    </div>
                    <div style={{ flex: 1 }}>
                        <p style={{
                            fontSize: 14
                        }}>{item.service}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p style={{
                            fontSize: 14
                        }}>{item.amount}</p>
                    </div>
                    <div style={{ flex: 1 }}>
                        <p style={{
                            fontSize: 14
                        }}>{item.reimbursable}</p>
                    </div>
                    <div style={{
                        width: 80,
                        color: 'red',
                        cursor: 'pointer',
                        fontWeight: 600
                    }} onClick={() => {
                        this.setState({
                            decline: item.transId
                        })
                    }}>
                        {this.state.data.approvalStatus === 'Declined' || this.state.data.approvalStatus === "Approved" ? "" : <p>Decline</p>}

                    </div>
                </div>
            </div>
        )
    }


    render() {

        if (this.state.loading) {
            return <PageLoader />
        }
        let data = this.state.data;
        return (
            <div style={{
                height: '100vh'
            }} className="light">
                <div style={{
                    borderBottom: '2px dashed #574fdf',
                    textAlign: 'center',
                    color: 'rgba(0,15,50,0.65)',
                    backgroundColor: '#fff'
                }} className="dotted ptb-70 ptb-40">
                    <div>
                        <h1 style={{ marginTop: 20 }} className="integration-title">{data.title}</h1>
                        <p style={{ marginTop: 10 }} className="integration-desc">{data.details}</p>
                    </div>
                </div>
                <div style={{
                    background: '#f2f4f6',
                    minHeight: '90vh'
                }} className="ptb-70 ptb-40">
                    <div className="container-size">
                        <div style={{
                            padding: 20,
                            background: '#fff',
                            border: '1px solid #dedede',
                            boxShadow: '2px 1px 1px rgba(1,1,1,.1)',
                            width: '100%'

                        }}>
                            <div style={{ marginBottom: 30 }}>
                                <h2 style={{
                                    fontSize: 25,
                                    color: "#454545",
                                    fontWeight: 700
                                }}>Voucher Details</h2>
                                <p style={{
                                    marginBottom: 20,
                                    fontSize: 13,
                                    color: "#898989",
                                    marginTop: 4
                                }}>All the voucher details available here:-</p>
                            </div>

                            <div style={{
                                marginBottom: 30
                            }}>
                                <p style={{
                                    fontSize: 11,
                                    marginBottom: 5,
                                    color: "#787878"
                                }}>
                                    Name :-
								</p>
                                <p style={{
                                    fontSize: 17,
                                    color: "#343434",
                                    fontWeight: 600,
                                    borderBottom: '1px solid #dedede',
                                    paddingBottom: 4
                                }}>
                                    {data.employee ? data.employee.name : "Not Defined"}
                                </p>
                            </div>
                            <div style={{
                                marginBottom: 30
                            }}>
                                <p style={{
                                    fontSize: 11,
                                    marginBottom: 5,
                                    color: "#787878"
                                }}>
                                    Code :-
								</p>
                                <p style={{
                                    fontSize: 17,
                                    color: "#343434",
                                    fontWeight: 600,
                                    borderBottom: '1px solid #dedede',
                                    paddingBottom: 4
                                }}>
                                    {data.employee ? data.employee.code : "Not Defined"}
                                </p>
                            </div>
                            {
                                data.values.map((item, index) => {
                                    return (
                                        <div style={{
                                            marginBottom: 30
                                        }} key={index}>
                                            <p style={{
                                                fontSize: 11,
                                                marginBottom: 5,
                                                color: "#787878"
                                            }}>
                                                {item.key} :-
																	</p>
                                            <p style={{
                                                fontSize: 17,
                                                color: "#343434",
                                                fontWeight: 600,
                                                borderBottom: '1px solid #dedede',
                                                paddingBottom: 4
                                            }}>
                                                {item.value ? item.value : "Not Defined"}
                                            </p>
                                        </div>
                                    )
                                })
                            }

                            <div style={{
                                marginBottom: 30
                            }}>
                                <p style={{
                                    fontSize: 11,
                                    marginBottom: 5,
                                    color: "#787878"
                                }}>
                                    Approval Status :-
								</p>
                                <p style={{
                                    fontSize: 17,
                                    color: "#343434",
                                    fontWeight: 600,
                                    borderBottom: '1px solid #dedede',
                                    paddingBottom: 4
                                }}>
                                    {data.permissionStatus ? data.permissionStatus : "Not Defined"}
                                </p>
                            </div>

                            <div style={{ marginBottom: 30 }}>
                                <h2 style={{
                                    fontSize: 25,
                                    color: "#454545",
                                    fontWeight: 700
                                }}>Transaction Details</h2>
                                <p style={{
                                    marginBottom: 20,
                                    fontSize: 13,
                                    color: "#898989",
                                    marginTop: 4
                                }}>All the transaction details available here:-</p>
                            </div>

                            <NormalTable
                                data={data.transactions}
                                mapItem={this.mapItem}
                                headers={
                                    [
                                        {
                                            name: 'Details',
                                            weight: 1
                                        },
                                        {
                                            name: 'Expense Head',
                                            weight: 1
                                        },
                                        {
                                            name: 'Amount',
                                            weight: 1
                                        },
                                        {
                                            name: 'Settled',
                                            weight: 1
                                        },
                                        {
                                            name: '',
                                            width: 80
                                        },
                                    ]
                                }
                            />


                            {data.approvalStatus === 'Declined' || data.approvalStatus === "Approved" ? "" :

                                <div style={{
                                    display: 'flex',
                                    flex: 1
                                }}>
                                    <p style={{
                                        marginTop: 20,
                                        color: "white",
                                        padding: 15,
                                        flex: 1,
                                        cursor: 'pointer',
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        background: '#6367FA'
                                    }} onClick={() => {
                                        let data = this.props.location.search.substr(1)
                                        data = Query.parse(data)
                                        let token = data.token;
                                        post(`/others/action/voucher`, {
                                            token,
                                            action: 'approve',
                                            remarks: '',
                                            transId: ''
                                        }, (e, r) => {
                                            showToast({
                                                type: 'success',
                                                message: 'Voucher approved Successfully'
                                            })
                                            this.init();
                                        })
                                    }}>
                                        Approve Successfully
								</p>

                                    <p style={{
                                        marginTop: 20,
                                        color: "white",
                                        padding: 15,
                                        marginLeft: 20,
                                        flex: 1,
                                        cursor: 'pointer',
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        background: '#fa736b'
                                    }} onClick={() => {
                                        this.setState({
                                            ...this.state,
                                            declineVoucher: true
                                        })
                                    }}>
                                        Decline Task
									</p>
                                </div>
                            }
                        </div>
                    </div>



                    {this.state.decline ?
                        <Modal
                            external={true}
                            show={this.state.decline}
                            close={() => {
                                this.setState({
                                    decline: undefined
                                })
                            }}
                            title="Decline Transaction"
                            desc="Please enter the remarks to decline this transaction"
                            style={{
                                save: {
                                    width: '100%',
                                    height: 45,
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            }}
                            buttonName="Decline Transaction"
                            onSave={() => {
                                if (!this._decline.getValue()) {
                                    showToast({
                                        type: 'error',
                                        message: 'Please enter a decline reason'
                                    })
                                    return;
                                }

                                let data = this.props.location.search.substr(1)
                                data = Query.parse(data)
                                let token = data.token;
                                post(`/others/action/voucher`, {
                                    token,
                                    action: 'removeTxn',
                                    remarks: this._decline.getValue(),
                                    transId: this.state.decline
                                }, (e, r) => {
                                    showToast({
                                        type: 'success',
                                        message: 'Transaction declined Successfully'
                                    })
                                    this.init();
                                    this.setState({
                                        decline: undefined
                                    })
                                })
                            }}
                        >
                            <FloatInput1 type="text" handleRef={ref => this._decline = ref} title="Decline Remarks" />
                        </Modal> : null


                    }

                    {this.state.declineVoucher ?
                        <Modal
                            external={true}
                            show={this.state.declineVoucher}
                            close={() => {
                                this.setState({
                                    declineVoucher: undefined
                                })
                            }}
                            title="Decline Voucher"
                            desc="Please enter the remarks to decline this voucher"
                            style={{
                                save: {
                                    width: '100%',
                                    height: 45,
                                    backgroundColor: 'red',
                                    borderColor: 'red',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }
                            }}
                            buttonName="Decline Voucher"
                            onSave={() => {
                                if (!this._declineVoucher.getValue()) {
                                    showToast({
                                        type: 'error',
                                        message: 'Please enter a decline reason'
                                    })
                                    return;
                                }

                                let data = this.props.location.search.substr(1)
                                data = Query.parse(data)
                                let token = data.token;
                                post(`/others/action/voucher`, {
                                    token,
                                    action: 'decline',
                                    remarks: this._declineVoucher.getValue(),
                                    transId: ''
                                }, (e, r) => {

                                    showToast({
                                        type: 'success',
                                        message: 'Voucher declined Successfully'
                                    })
                                    this.init();
                                    this.setState({
                                        declineVoucher: undefined
                                    })
                                })
                            }}
                        >
                            <FloatInput1
                                placeholder="Enter the decline remarks"
                                type="text" handleRef={ref => this._declineVoucher = ref} title="Decline Remarks" />
                        </Modal> : null


                    }

                </div>
            </div>
        )
    }
}

export default Index;
