import React from 'react'
import {connect} from "react-redux";
import {AuthActions} from '../../Redux/Reducer/Auth';
import Back from './emp.jpg'
import bs from './bootstrap.module.css'
import LoginWithDice from '../../Component/Common/LoginWithDice'
import Moment from 'moment';
import {useHistory} from 'react-router-dom'

function Login(props) {

	let HISTORY = useHistory();
    if (props.auth.authenticated) {
        HISTORY.push('/app/home')
    }

	return (
		<>
			<div style={{
			background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)) center / cover no-repeat, url('${Back}') center / cover no-repeat`,
			height: '100vh',
			fontSize: '16px'
			}}>
				<div className={`${bs.container} ${bs['h-100']}`} style={{maxWidth: '1500px'}}>
					<div className={`${bs.row} ${bs['justify-content-center']} ${bs['align-items-center']} ${bs['h-100']}`} style={{color: '#333'}}>
						
						<div data-aos="fade-right" className={`${bs['col-md-6']} ${bs['d-none']} ${bs['d-sm-none']} ${bs['d-md-flex']} ${bs['text-white']}`}>
							<div style={{maxWidth: '400px', margin: 'auto'}}>
								<h1 className={`${bs['display-3']} ${bs['fw-bold']}`}>Dice Employee Portal.</h1>
								<p>Dice Employee portal makes it easy for employees to use the services and features provided by the Dice all at one place.</p>
							</div>
						</div>

						<div className={`${bs['col-md-6']} ${bs['h-100']} ${bs['p-0']}`}>
							<div data-aos="fade-left" className={`${bs['text-center']} ${bs['shadow-lg']}`} style={{
								backgroundColor: '#fff',
								marginl:'atuo',
								width:'100%',
								maxWidth: '450px',
								boxShadow: '0 1rem 2rem rgba(0,0,0,0.3)',
								minWidth: '280px',
								overflow: 'hidden',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								padding: '0rem 1rem',
								height:'100%'
							}}>
								<LoginWithDice/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}


export default connect(state => {
	return {
		auth: state.auth
	}
}, dispatch => {
	return {
        login: data => {
            dispatch({
                type: AuthActions.Login,
                data: {
                    name: data.name,
                    login: Moment()
                }
            })
        }
    }
})(Login);
