import React from 'react';

const textLastColor = (text) => {
    if (text.toLowerCase().includes("**")) {
        let newValue = text.slice(0, text.length - 2);
        let star = <span style={{ color: '#ff6666' }} >**</span>
        return <span >{newValue}{star}</span>

    } else if (text.toLowerCase().includes("*")) {
        let newValue = text.slice(0, text.length - 1);
        let star = <span style={{ color: '#ff6666' }}>**</span>
        return <span>{newValue}{star}</span>

    }

    return text;
}

export { textLastColor }
